
 .font-link {
  font-family: "Times New Roman", Times, serif;
}
.font-link-footer {
  font-family: "Times New Roman", Times, serif;
  text-align: left;
  /* background-color: #D9D9D9; */
  color: #000;
  border: 1px solid black !important;
  /* border-top: none !important; */
}
.font-link-footer h6{
  text-align: center;
  border-bottom: 1px solid black;
  padding: 2px 0px;
}

.single-border-table > tr > th, .single-border-table > tr > td{
  border: none !important;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;

}

.single-border-table > tr > td:last-child, .single-border-table > tr > th:last-child {
  border-right: 1px solid black !important;
}

.single-border-table > tr:last-child td, .single-border-table > tr:last-child th {
  border-bottom: 1px solid black !important;
}

.single-border-table td.spacial, .single-border-table th.spacial {
  border-bottom: 1px solid black !important;
}

#pagbreaksborder {
  border-top: solid 1px #0000 !important;
  border-bottom: solid 1px #0000 !important;
  page-break-inside:avoid !important;
}